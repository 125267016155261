window.callRWR =
  window.callRWR ||
  ((...params) =>
    window.addEventListener('initializedRWR', () => window.callRWR(...params)));

window.delighted = window.delighted || {
  survey: (...params) =>
    window.addEventListener('initializedDelighted', () =>
      window.delighted.survey(...params)
    ),
};
